.container {
  color: white;
}

.logoContainer {
  height: 5rem;
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}
