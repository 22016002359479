.pageTitle {
  color: white;
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.carouselContainer {
  height: 5rem;
}

.icon {
  height: 10rem;
  width: auto;
  margin: auto;
  object-fit: cover;
}

.caption {
  margin-top: 1rem;
}

@media screen and (min-width: 910px) {
  .icon {
    height: 15rem;
  }
}
