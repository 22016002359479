.whatsAppIcon {
  position: fixed;
  right: 0;
  top: 90%;
  font-size: 4rem;
  color: white;
  background-color: transparent;
  border: none;
  transition: color 0.5s ease-in-out;
  border-radius: 8%;
}

.whatsAppIcon:hover {
  color: #29b24c;
}
