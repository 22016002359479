body {
  margin: 0;
  font-size: 16px;
  background-image: url(./chalkboard.png);
  width: 100%;
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
