.googleMap {
  width: 100%;
  height: 25rem;
  margin-top: 3rem;
  animation: fadeIn 2s forwards;
}

.pageTitle {
  font-family: "Montserrat", sans-serif;
  color: white;
  font-weight: 800;
  font-size: 1.5rem;
  margin-top: 3.5rem;
  text-align: center;
}

.addressContainer {
  margin-top: 3rem;
  line-height: 2rem;
  text-align: center;
}

.textGreen {
  color: #29b24c;
  font-size: 2rem;
  text-transform: uppercase;
}

.address {
  font-family: "Montserrat";
  font-weight: 600;
  color: white;
  text-align: center;
  font-size: 1rem;
}

.phoneNumber {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  color: white;
  display: block;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 3rem;
}

.addressIcon {
  color: #29b24c;
  font-size: 2rem;
  display: block;
  margin: auto auto 1rem auto;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
